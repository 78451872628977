<template>
  <nav class="navigation">
    <a
      href="#"
      :class="['hamburger', { 'hamburger--visible': menuVisible }]"
      @click="menuClick()"
      title="Rozwiń menu"
    >
      <div class="hamburger__stripe"></div>
      <div class="hamburger__stripe"></div>
      <div class="hamburger__stripe"></div>
    </a>
    <ul :class="['menu', { 'menu--visible': menuVisible }]">
      <li
        v-for="item in navigation"
        :key="item.name"
        :class="['menu__item', { 'menu__item--disabled': !item.available }]"
      >
        <span v-if="!item.available">
          <img
            class="menu__item--disabled-icon"
            src="/img/padlock.svg"
            alt="niedostępne"
          />
          {{ item.name }}
        </span>
        <router-link
          v-if="item.available"
          @click="menuClick()"
          :title="`Link do zakładki: ${item.name}`"
          :to="{ path: `/${item.path}` }"
          >{{ item.name }}</router-link
        >
        <p
          v-if="
            item.completed !== '1' &&
            item.available &&
            !safeElements.includes(item.name)
          "
          class="menu__tooltip menu__tooltip--complete"
        >
          Uzupełnij zakładkę
        </p>
        <p v-if="!item.available" class="menu__tooltip">{{ item.message }}</p>
        <svg
          v-if="item.completed === '1' && item.available"
          class="menu__item--completed"
          viewBox="0 0 13 10"
        >
          <path
            id="Check"
            :class="[
              'tick',
              { 'tick--white': `/${item.path}` === $route.path },
            ]"
            d="M347.5,21.5,346,23l4,4,9-8.5L357.5,17,350,24Z"
            transform="translate(-346 -17)"
          />
        </svg>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import checkIfServiceIsBought from "@/mixins/checkIfServiceIsBought.js";

export default {
  mixins: [checkIfServiceIsBought],
  computed: {
    ...mapGetters([
      "currentCompanyPackage",
      "activeEditionDetails",
      "namedDetails",
    ]),
    dateTimeNow() {
      const dateNow = new Date().toISOString().slice(0, 10);
      return dateNow;
    },
  },
  data() {
    return {
      menuVisible: false,
      navigation: {
        informations: {
          name: "Informacje organizacyjne",
          path: "informacje-organizacyjne",
          available: true,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        package: {
          name: "Mój pakiet",
          path: "moj-pakiet",
          available: true,
          completed: true,
        },
        exhibitor_details: {
          name: "Dane wystawcy",
          path: "dane-wystawcy",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        exhibitor_booth: {
          name: "Stoisko wystawcy",
          path: "stoisko-wystawcy",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        erecruiter: {
          name: "Zbieranie aplikacji",
          path: "zbieranie-aplikacji",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        catalog_virtual_jobicon: {
          name: "Wizytówka w Wirtualnym JOBICONIE",
          path: "wizytowka-w-wirtualnym-jobiconie",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        virtual_jobicon: {
          name: "Moje ogłoszenia w Wirtualnym JOBICONIE",
          path: "moje-ogloszenia-na-wydarzeniu",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        // promotional_ads: {
        //   name: "Ogłoszenia promocyjne",
        //   path: "ogloszenia-promocyjne",
        //   available: false,
        //   completed: false,
        //   message: "Twoja firma nie wykupiła tej usługi",
        // },
        lectures: {
          name: "Prelekcja",
          path: "prelekcja",
          available: false,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
        contact: {
          name: "Kontakt",
          path: "kontakt",
          available: true,
          completed: false,
          message: "Twoja firma nie wykupiła tej usługi",
        },
      },
      safeElements: ["Informacje organizacyjne", "Mój pakiet", "Kontakt"],
      elementsToCheck: ["Prelekcja"],
      elementsNotInClassic: [],
      specialAdsElements: [
        "Zbieranie aplikacji",
        "Moje ogłoszenia w Wirtualnym JOBICONIE",
      ],
    };
  },
  methods: {
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    checkEditionAvailability: function () {
      this.$nextTick(function () {
        const allMenuIItems = this.navigation;
        const editionStartDateString = new Date(
          this.activeEditionDetails.start_date
        )
          .toISOString()
          .slice(0, 10);
        const editionEndDateString = new Date(
          this.activeEditionDetails.end_date
        )
          .toISOString()
          .slice(0, 10);
        const adsEndDate = new Date(this.activeEditionDetails.adverts_end_date)
          .toISOString()
          .slice(0, 10);

        for (const loopMenuItem in allMenuIItems) {
          if (allMenuIItems.hasOwnProperty(loopMenuItem)) {
            const menuItem = allMenuIItems[loopMenuItem];
            if (
              this.dateTimeNow >= editionEndDateString ||
              editionStartDateString >= this.dateTimeNow
            ) {
              menuItem.available = this.safeElements.includes(menuItem.name);
              if (
                this.dateTimeNow < adsEndDate &&
                this.elementsNotInClassic.includes(menuItem.name)
              ) {
                menuItem.available = true;
              }

              if (
                this.dateTimeNow < adsEndDate &&
                this.dateTimeNow > editionStartDateString &&
                this.specialAdsElements.includes(menuItem.name)
              ) {
                menuItem.available = true;
              }

              if (!menuItem.available) {
                menuItem.message = "Nie można edytować tej zakładki";
              }
            } else {
              if (this.elementsToCheck.includes(menuItem.name)) {
                menuItem.available = this.checkIfServiceIsBought(menuItem.name);
              } else {
                if (this.elementsNotInClassic.includes(menuItem.name)) {
                  menuItem.available =
                    this.currentCompanyPackage.name !== "Classic";
                } else {
                  menuItem.available = true;
                }
              }
            }
          }
        }
      });
    },
    checkPageCompletion: function (menuItem, optionItem) {
      const edition = this.activeEditionDetails.id;
      if (this.namedDetails[`${edition}${optionItem}`] === undefined) {
        this.navigation[menuItem].completed = "0";
      } else {
        this.navigation[menuItem].completed =
          this.namedDetails[`${edition}${optionItem}`].confirmed;
      }
    },
    checkPagesCompletion: function () {
      this.checkPageCompletion("exhibitor_details", "_ed_disabled");
      this.checkPageCompletion("exhibitor_booth", "_eb_disabled");
      this.checkPageCompletion("erecruiter", "_er_disabled");
      this.checkPageCompletion("catalog_virtual_jobicon", "_bc_disabled");
      this.checkPageCompletion("virtual_jobicon", "_vj_disabled");
      this.checkPageCompletion("lectures", "_lt_disabled");
      // this.checkPageCompletion("promotional_ads", "_pa_disabled");
    },
    menuClick() {
      this.menuVisible = !this.menuVisible;
    },
  },
  mounted() {
    this.checkEditionAvailability();
    this.checkPagesCompletion();
  },
  watch: {
    activeEditionDetails: function () {
      this.checkEditionAvailability();
      this.checkPagesCompletion();
    },
    namedDetails: function () {
      this.checkPagesCompletion();
    },
  },
};
</script>
<style lang="scss">
.navigation {
  width: 0;
  @include desktop {
    width: 30%;
    margin-right: 20px;
  }
  @include large-desktop {
    width: 25%;
    margin-right: 80px;
  }
}

.menu {
  list-style-type: none;
  margin: 0;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9000;
  overflow: scroll;
  @include desktop {
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
    margin-top: 0;
    height: auto;
    width: auto;
    z-index: 1;
    opacity: 1;
    visibility: visible;
  }

  &--visible {
    opacity: 1;
    visibility: visible;
    background: #fff;
  }

  &__tooltip {
    position: absolute;
    display: none;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    left: 104%;
    z-index: -1;
    background: $medium-gray-color;
    color: #fff;
    min-width: 240px;

    @include large-desktop {
      left: 102%;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      z-index: -1;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: #fff $medium-gray-color #fff #fff;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      transition: 0s;
    }
  }

  &__item {
    text-align: center;
    position: relative;
    display: block;
    z-index: 2;
    font-size: 14px;

    a,
    span {
      padding: 15px 20px;
      min-width: 250px;
      display: inline-block;
      border-bottom: 1px solid $light-gray-color;
      font-weight: 500;
      width: calc(100% - 40px);
      @include desktop {
        margin-left: 20px;
      }

      @include large-desktop {
        padding: 20px 0;
      }
    }

    .router-link-active,
    a:hover {
      background-color: $primary-color;
      color: #fff;
    }

    &--disabled {
      color: $light-gray-color;
      .router-link-active,
      a:hover {
        background-color: #fff;
        color: $light-gray-color;
      }
    }

    &--disabled-icon {
      width: 10px;
      margin-bottom: 2px;
      margin-left: 10px;
    }

    &--completed {
      width: 13px;
      position: absolute;
      right: 15px;
      top: 27px;
    }

    &:hover {
      color: $light-gray-color;

      .menu__tooltip {
        display: block;
      }

      .tick {
        fill: #fff;
      }
    }

    &:first-child,
    &:last-child,
    &:nth-child(2) {
      color: $primary-color;

      a {
        font-weight: bold;
      }
    }
  }
}

.hamburger {
  display: block;
  width: 35px;
  height: 30px;
  z-index: 9999;
  position: absolute;
  top: 30px;
  left: 10px;

  @include desktop {
    display: none;
  }

  .hamburger__stripe {
    background: $primary-color;
    width: 100%;
    height: 4px;
    margin: 5px 0;
    transition: transform 300ms ease, opacity 500ms ease, visibility 500ms ease;

    &:nth-child(1) {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &--visible {
    .hamburger__stripe {
      &:nth-child(1) {
        transform: rotate(40deg) translateY(0px);
        top: 0;
        transform-origin: 0%;
      }
      &:nth-child(2) {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
      }
      &:nth-child(3) {
        transform: rotate(-40deg) translateY(-9px);
        transform-origin: 50%;
      }
    }
  }
}
</style>
